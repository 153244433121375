// Adds ctx.getTransform() - returns an SVGMatrix
// Adds ctx.transformedPoint(x,y) - returns an SVGPoint
import React, {useEffect, useRef} from "react";

export const BlockPageScroll = ({children}) => {
    const scrollRef = useRef(null)
    useEffect(() => {
        const scrollEl = scrollRef.current
        scrollEl.addEventListener('wheel', stopScroll)
        return () => scrollEl.removeEventListener('wheel', stopScroll)
    }, [])
    const stopScroll = e => e.preventDefault()
    return (
        <div ref={scrollRef} style={{width: '100%', height: '100%'}} >
            {children}
        </div>
    )
}

export const getCanvasAndContext = (canvasRef) => {

    let result = {
        canvas: null,
        ctx: null
    }

    if (canvasRef && canvasRef.current) {
        result.canvas = canvasRef.current
        result.ctx = canvasRef.current.getContext('2d')
    }

    return result
}

export function IsImageLoadedAndWorking(image) {
    if (image && image.complete && (!(typeof image.naturalWidth !== "undefined" && image.naturalWidth === 0))) {
        return true
    } else {
        return false
    }
}

export function IsImageLoadedAndBroken(image) {
    if (image && image.complete && (typeof image.naturalWidth !== "undefined" && image.naturalWidth === 0)) {
        return true
    } else {
        return false
    }
}

export function getMousePos(canvas, evt) {
    var rect = canvas.getBoundingClientRect();
    return {
        x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
        y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
    };
}