import React, {useState, useEffect} from 'react';
import './CanvasExampleRenderer.css';
import Grid from '@material-ui/core/Grid';
import CanvasRenderer from '../canvas/CanvasRenderer'
import CanvasExampleMenu from './CanvasExampleMenu'
import AppRoutes from './AppRoutes'

import Hidden from '@material-ui/core/Hidden';

const CanvasExampleRenderer = () => {

    const [tileData, setTileData] = useState(null)
    const [settings, setSettings] = useState(null)
    const [layers, setLayers] = useState(null)

    const dataWasLoaded = (tileData, layers, settings) => {
        setTileData(tileData)
        setLayers(layers)
        setSettings(settings)
    }

    const [renderOptions, setRenderOptions] = useState({
        baseUrl: '//web.froso.de/crop/testdata/2/',
        debug: true,
        menu: true
    })

    AppRoutes.extractParamsFromCurrentUrl(AppRoutes.SingleMainRoute, renderOptions)

    console.log(renderOptions)
    let fullSettings = settings && renderOptions && Object.assign({}, settings, renderOptions)

    return (
            <Grid container spacing={0}>
                <Grid item xs={12} md={(renderOptions.menu && 9) || 12} style={{height: window.innerHeight*0.9}}>
                    <div key={renderOptions.baseUrl}
                         style={{width: '100%', height: '100%', minHeight: '320px'}}><CanvasRenderer
                        settings={renderOptions} dataLoadedCallback={dataWasLoaded}/>
                    </div>
                </Grid>
                {renderOptions.menu && <Hidden smDown><Grid mdDown={"hidden"} item xs={12} md={3}>
                    <div style={{height: window.innerHeight - 100 + 'px', overflow: 'scroll'}}>
                        <CanvasExampleMenu layers={layers}
                                           tileData={tileData}
                                           settings={fullSettings}
                                           renderOptions={renderOptions}
                                           setSettingsSafe={(newRenderOptions) => {
                                               if (newRenderOptions.baseUrl !== settings.baseUrl) {
                                                   AppRoutes.changeUrlWithoutReloading(AppRoutes.SingleMainRoute, {
                                                       baseUrl: newRenderOptions.baseUrl
                                                   })
                                               }

                                               setRenderOptions(Object.assign({}, renderOptions, newRenderOptions))
                                           }}/>
                    </div>
                </Grid></Hidden>}


            </Grid>
    )
}


export default CanvasExampleRenderer;