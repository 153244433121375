import React, {Component} from 'react';
import './App.css';
import CanvasExampleApp from './example/CanvasExampleApp'
import CssBaseline from '@material-ui/core/CssBaseline';
//import CanvasLocalLoader from "./canvas/CanvasLocalLoader";


const app = <CanvasExampleApp></CanvasExampleApp>

//const localApp = <CanvasLocalLoader></CanvasLocalLoader>

class App extends Component {

    render() {
        return <React.Fragment>
            <CssBaseline/>
            {app}
        </React.Fragment>
    }
}

export default App;
