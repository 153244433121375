function getUrlParameter(name) {
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window && window.location.search);
    return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '));
};


const AppRoutes = {
    SingleMainRoute: {
        id: '',
        title: ''
    },


    changeUrlWithoutReloading: (page, queryParams) => {
        if (!queryParams) {
            queryParams = {}
        }

        window && window.history.pushState('', '', Object.keys(queryParams).reduce((total, key, index) => total /*+ queryParamsPrefix*/ + key + '=' + queryParams[key] + (index < Object.keys(queryParams).length - 1 ? '&' : ''), '?' + (Object.keys(queryParams).length > 1 ? '&' : '')))
    },

    extractParamsFromCurrentUrl: (page, initialValues ) => {

        var queryParamsPrefix = page.id
        var foundDifferentValueInUrl = false
        Object.keys(initialValues).forEach((key) => {
            var urlValue = getUrlParameter(queryParamsPrefix+key)
            if (urlValue !== undefined && urlValue !== initialValues[queryParamsPrefix+key]) {
                initialValues[key] = urlValue
                foundDifferentValueInUrl = true
            }
        })

        return foundDifferentValueInUrl
    }
}

export default AppRoutes