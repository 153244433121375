
function enrichLayers(layers) {

    return layers.map(layer => {

        layer.enabled = true
        layer.data = layer.data.map(data => {

            let {type} = data
            if (type === 'croprow') {
                let {contour, lineWidth} = data

                data.x1 = Math.min(...(contour.map(c => c.x))) - lineWidth
                data.y1 = Math.min(...(contour.map(c => c.y))) - lineWidth
                data.x2 = Math.max(...(contour.map(c => c.x))) + lineWidth
                data.y2 = Math.max(...(contour.map(c => c.y))) + lineWidth

            } else if (type === 'roi') {
                let {contour} = data

                data.x1 = Math.min(...(contour.map(c => c.x)))
                data.y1 = Math.min(...(contour.map(c => c.y)))
                data.x2 = Math.max(...(contour.map(c => c.x)))
                data.y2 = Math.max(...(contour.map(c => c.y)))

            } else if (type === 'instance') {
                let {boundingBox} = data

                data.x1 = boundingBox.x
                data.x2 = data.x1 + boundingBox.width
                data.y1 = boundingBox.y
                data.y2 = data.y1 + boundingBox.height
            }


            return data
        })

        return layer

    })

}

function enrichTileData(tileData) {
    return tileData
}

function enrichSettings(settings) {
    return settings
}


export {enrichTileData, enrichLayers, enrichSettings}
