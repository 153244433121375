import React, {useEffect, useRef, useState} from 'react';
import Measure from 'react-measure'
import '../example/CanvasExampleRenderer.css';
import {
    BlockPageScroll,
    getCanvasAndContext,
    getMousePos
} from './CanvasRendererHelper'

import {
    drawEverything
} from './CanvasRendererDrawMethods'

import PropTypes from 'prop-types';

const CanvasRendererCanvas = ({layers, tileData, settings}) => {

    let [canvasDimensions, setCanvasDimensions] = useState(null)
    let [forceRender, setForceRender] = useState(null)
    let [camera, setCamera] = useState({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        zoom: 1.0,
        isDragging: false,
        lastPos: null,
        mousePos: null,
        dimensions: null
    })
    let canvasRef = useRef(null)

    /*
        // move to center on load
        //tileHash = JSON.stringify(tileData)
        useEffect(() => {
            //let {ctx} = getCanvasAndContext(canvasRef)
            //ctx.translate(10,0)
            // ctx.translate(settings.size.width * tileData.width * 0.5, 0);
            // ctx.translate(tileWidth *  tileData.width  * 0.25, tileHeight * tileData.height * 0.25)

            var zx = camera.width / tileData.width*settings.tiles.width
            var zy = camera.height / tileData.height*settings.tiles.height
            console.log('do it zoom')
           // camera.zoom = Math.min(zx, zy)
        }, [])

        useEffect(() => {
            let {canvas} = getCanvasAndContext(canvasRef)
            // camera.x = canvas.width / 2
            //camera.y = canvas.height / 2;
            //   trackTransforms(ctx);
        }, [canvasRef])
    */


    // update canvas dimensions on change
    useEffect(() => {
        var w = camera.width
        var h = camera.height
        var d = canvasDimensions
        if (canvasDimensions) {
            w = canvasDimensions.width / camera.zoom
            h = canvasDimensions.height / camera.zoom
        }

        setCamera({...camera, width: w, height: h, dimensions: d})
    }, [canvasDimensions])

    // draw everything and redraw if something is loading yet
    const draw = () => {
        let {canvas, ctx} = getCanvasAndContext(canvasRef)
        let foundIncomplete = drawEverything(ctx, canvas, tileData, layers, settings, camera)

        if (foundIncomplete) {
            setTimeout(() => {
                setForceRender({...camera})
            }, 500)
        }
    }

    useEffect(() => {
        draw()
    })


    return (
        <BlockPageScroll>
            <Measure
                bounds
                onResize={contentRect => {
                    setCanvasDimensions(contentRect.bounds)
                }}
            >{({measureRef}) => (
                <div ref={measureRef} style={{width: '100%', height: '100%'}} >
                    <canvas id="canvas" ref={canvasRef}
                            width={(canvasDimensions && canvasDimensions.width*0.9)}
                            height={(canvasDimensions && canvasDimensions.height*0.9)}
                            onMouseDown={function (evt) {
                                let {canvas} = getCanvasAndContext(canvasRef)
                                let mousePos = getMousePos(canvas, evt)
                                setCamera((camera) => {

                                    return {
                                        ...camera,
                                        isDragging: true,
                                        dragMouseStart: mousePos,
                                        dragPosStart: {x: camera.x, y: camera.y},
                                        mousePos: mousePos,
                                        lastPos: mousePos
                                    }
                                })
                            }}

                            onMouseMove={function (evt) {
                                let {canvas} = getCanvasAndContext(canvasRef)
                                let mousePos = getMousePos(canvas, evt)
                                setCamera((camera) => {
                                    let cameraX = camera.x
                                    let cameraY = camera.y
                                    if (camera.isDragging) {
                                        cameraX = camera.dragPosStart.x - (mousePos.x - camera.dragMouseStart.x) / camera.zoom
                                        cameraY = camera.dragPosStart.y - (mousePos.y - camera.dragMouseStart.y) / camera.zoom
                                    }
                                    return {...camera, mousePos: mousePos, lastPos: mousePos, x: cameraX, y: cameraY}
                                })
                            }}

                            onMouseUp={function () {
                                setCamera((camera) => {
                                    return {...camera, isDragging: false}
                                })
                            }}

                            onWheel={function (evt) {
                                let delta = evt.nativeEvent.wheelDelta ? evt.nativeEvent.wheelDelta / 40 : evt.nativeEvent.deltaY ? -evt.nativeEvent.deltaY : 0;
                                let {canvas} = getCanvasAndContext(canvasRef)
                                let diff = getMousePos(canvas, evt)
                                if (delta) {
                                    setCamera((camera) => {

                                        let factor = Math.pow(settings.camera.scaleFactorOnWheelUpdate, delta);
                                        let newCameraZoom = camera.zoom * factor

                                        let cameraX = camera.x + (diff.x / camera.zoom - diff.x / newCameraZoom)
                                        let cameraY = camera.y + (diff.y / camera.zoom - diff.y / newCameraZoom)
                                        let cameraWidth = (camera.dimensions && camera.dimensions.width / newCameraZoom) || camera.width
                                        let cameraHeight = (camera.dimensions && camera.dimensions.height / newCameraZoom) || camera.height

                                        return {
                                            ...camera,
                                            x: cameraX,
                                            y: cameraY,
                                            width: cameraWidth,
                                            height: cameraHeight,
                                            zoom: newCameraZoom
                                        }
                                    })
                                }
                            }}
                    />
                </div>)}
            </Measure>
        </BlockPageScroll>
    )
}

CanvasRendererCanvas.propTypes = {
    layers: PropTypes.array.isRequired,
    tileData: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
}

export default CanvasRendererCanvas;