import React from 'react';
import CanvasExampleRenderer from "./CanvasExampleRenderer";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const CanvasExampleApp = () => {
    return (
        <div className="CanvasApp">
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography variant="body1" color="inherit">
                        Crop Renderer v0.4
                    </Typography>
                </Toolbar>
            </AppBar>

            <CanvasExampleRenderer/>
        </div>

    );
}


export default CanvasExampleApp;