import React from 'react';
import './CanvasExampleRenderer.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import ListSubheader from '@material-ui/core/ListSubheader';

const CanvasExampleMenu = ({layers, tileData, settings, setSettingsSafe, renderOptions}) => {
    return (
        <>
            {settings && <List subheader={<ListSubheader>General</ListSubheader>}>
                <ListItem>

                    <ListItemText primary={'Debug'}/>

                    <ListItemSecondaryAction>
                        <Switch
                            onChange={() => {
                                setSettingsSafe({debug: !settings.debug})
                            }}
                            checked={settings.debug}
                        />
                    </ListItemSecondaryAction>

                </ListItem>

            </List>}


            {settings && layers && <List subheader={<ListSubheader>Foreground-Layers</ListSubheader>}>
                {layers && layers.map((layer) => {

                    const foundIndex = settings.activeForegroundLayers.indexOf(layer.name)
                    const isActive = foundIndex > -1

                    return (<ListItem
                        key={layer.name}>

                        <ListItemText primary={layer.name}/>

                        <ListItemSecondaryAction>
                            <Switch
                                onChange={() => {

                                    if (isActive) {
                                        delete (settings.activeForegroundLayers[foundIndex])
                                    } else {
                                        settings.activeForegroundLayers.push(layer.name)
                                    }

                                    setSettingsSafe({activeForegroundLayers: settings.activeForegroundLayers})
                                }}
                                checked={isActive}
                            />
                        </ListItemSecondaryAction>

                    </ListItem>)
                })}

            </List>}


            {settings && tileData && <List subheader={<ListSubheader>Background-Layers</ListSubheader>}>
                {tileData && tileData.layers && tileData.layers.map((layer) => {

                    const isActive = settings.activeBackgroundLayer.prefix === layer.prefix

                    return <ListItem key={layer.name + isActive}>

                        <ListItemText primary={layer.name}/>

                        <ListItemSecondaryAction>
                            <Switch
                                onChange={() => {

                                    //TODO: not thaaat nice
                                    tileData.tiles.forEach(tile => {
                                        tile.images.forEach(image => {
                                            image.complete = false
                                            image.image = null
                                        })
                                    })

                                    setSettingsSafe({activeBackgroundLayer: layer})
                                }}
                                checked={isActive}
                            />
                        </ListItemSecondaryAction>

                    </ListItem>
                })}
            </List>}

            {renderOptions && <div>
                <input type="text" style={{width: '100%'}} value={renderOptions.baseUrl} onChange={(event) => {
                    setSettingsSafe({baseUrl: event.target.value})
                }}/>
            </div>}


        </>
    )

}


export default CanvasExampleMenu;