import React, {useState, useEffect} from 'react';
import {enrichTileData, enrichLayers, enrichSettings} from '../canvas/LoaderHelper'
import PropTypes from "prop-types";
import CanvasRendererCanvas from "./CanvasRendererCanvas";

const CanvasRenderer = ({settings, dataLoadedCallback}) => {

    const {baseUrl} = settings
    const [tileData, setTileData] = useState(null)
    const [defaultSettings, setDefaultSettings] = useState(null)
    const [layers, setLayers] = useState(null)

    const loadTiles = () => {
        fetch(baseUrl + 'meta/tiles.json')
            .then(response => response.json())
            .then(data => {
                setTileData(enrichTileData(data))
            })
    }

    const loadLayers = () => {
        fetch(baseUrl + 'meta/layer.json')
            .then(response => response.json())
            .then(data => {
                setLayers(enrichLayers(data.layers))
            })
    }

    const loadDefaultSettings = () => {
        fetch('settings.json')
            .then(response => response.json())
            .then(data => {
                setDefaultSettings(enrichSettings(data))
            })
    }

    useEffect(() => {
        loadTiles()
        loadLayers()
        loadDefaultSettings()
    }, [])

    useEffect( () => {
        dataLoadedCallback && dataLoadedCallback(tileData, layers, defaultSettings)
    }, [dataLoadedCallback, tileData, layers, defaultSettings])

    let fullSettings = defaultSettings && settings && Object.assign({}, defaultSettings, settings)

    return (
        <>{layers && tileData && fullSettings && <CanvasRendererCanvas layers={layers}
                                                                   tileData={tileData}
                                                                  settings={fullSettings}/>
        }</>)
}

CanvasRenderer.propTypes = {
    settings: PropTypes.object.isRequired,
    dataLoadedCallback: PropTypes.func
}


export default CanvasRenderer;