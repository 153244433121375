import {IsImageLoadedAndBroken, IsImageLoadedAndWorking} from "./CanvasRendererHelper";

export const drawDebug = (ctx, canvas, tileData, settings, camera) => {
    //ctx.save();
    const {tileWidth, tileHeight} = settings.tiles
    if (settings.debug) {
        ctx.font = "7px Arial";
        let dotSize = Math.min(5 / camera.zoom, 1)
        for (let x = 0; x < tileData.width; x += 1) {

            for (let y = 0; y < tileData.height; y += 1) {
                let px = x * tileWidth
                let py = y * tileHeight

                ctx.strokeStyle = '#ffffff55'
                ctx.lineWidth = 1;
                ctx.strokeRect(px, py, tileWidth, tileHeight)
                ctx.fillStyle = 'white'
                ctx.fillText(x + "/" + y, px, py);
                ctx.fillStyle = 'red'
                ctx.fillRect(px - dotSize * 0.5, py - dotSize * 0.5, dotSize, dotSize)
            }
        }
    }
    // ctx.restore();
    return false
}

export const drawTiles = (ctx, canvas, tileData, settings, camera) => {
    let foundIncomplete = false

    const {tileWidth, tileHeight} = settings.tiles
    // Render tiles
    ctx.save();

    let cameraWidth = camera.width
    let cameraHeight = camera.height

    tileData.tiles.forEach((tile) => {
        if (tile.images.length > 0) {


            let tileX = tile.x * tileWidth
            let tileY = tile.y * tileHeight
            let tileWidthInPixel = tileWidth * camera.zoom

            // if not visible, do nothing
            if (tileX + tileWidth < camera.x || tileY + tileHeight < camera.y || tileX > camera.x + cameraWidth || tileY > camera.y + cameraHeight) {
                return
            }


            let tileIndex = 0
            //find best suitable image
            for (let i = 0; i < tile.images.length; i++) {
                if (tile.images[i].width >= tileWidthInPixel) {
                    tileIndex = i

                    // optimization: we do not need to use a lower resolution image if the higher one is already loaded
                    if (IsImageLoadedAndWorking(tile.images[i].image)) {
                        break;
                    }
                }
            }

            // load if not yet done
            let entry = tile.images[tileIndex]
            if (!entry.image) {
                entry.image = new Image()
                entry.image.src = settings.baseUrl + 'tiles/' + settings.activeBackgroundLayer.prefix + entry.file
                // entry.image.onload = drawTiles(ctx, canvas, p1, p2, tileData, settings, camera.zoom, tileWidth, tileHeight)
            }


            if (IsImageLoadedAndWorking(entry.image)) {
                // find image with lower resolution
                //img = tile.imageOld
                ctx.drawImage(entry.image, tileX, tileY, tileWidth, tileHeight);

            } else {
                // we need to rerender as soon as the correct image was loaded

                // if the image is not there, do not wait for it
                if (!IsImageLoadedAndBroken(entry.image)) {
                    foundIncomplete = true
                }

                // try to find an image with lower resolution
                for (let i = 0; i < tile.images.length; i++) {
                    let entry = tile.images[i]
                    if (IsImageLoadedAndWorking(entry.image)) {
                        ctx.drawImage(entry.image, tileX, tileY, tileWidth, tileHeight);
                    }
                }

            }
        }
    })


    ctx.restore();

    return foundIncomplete
}


export const drawLayers = (ctx, canvas, layers, settings, camera) => {
    let cameraWidth = camera.width
    let cameraHeight = camera.height


    layers.forEach((layer) => {
        const foundIndex = settings.activeForegroundLayers.indexOf(layer.name)
        const isActive = foundIndex > -1

        if (!isActive) {
            return
        }

        layer.data.forEach((data) => {

            let x1 = data.x1
            let x2 = data.x2
            let y1 = data.y1
            let y2 = data.y2

            // if not visible, do nothing
            if (x2 < camera.x || y2 < camera.y || x1 > camera.x + cameraWidth || y1 > camera.y + cameraHeight) {
                return
            }

            /* // if not visible, do nothing
             if (x1 > p2.x || y1 > p2.y || x2 < p1.x || y2 < p1.y) {
                 return
             }*/


            ctx.save();

            let {type} = data
            if (type === 'croprow') {
                let {contour, lineWidth} = data
                let {color} = settings.croprow
                ctx.beginPath();
                ctx.lineWidth = Math.max(0.5, lineWidth / camera.zoom)
                ctx.strokeStyle = color
                ctx.moveTo(contour[0].x, contour[0].y)
                ctx.lineTo(contour[1].x, contour[1].y)
                ctx.stroke()
            } else if (type === 'roi') {
                let {contour} = data
                let {fillColor} = settings.roi
                ctx.beginPath();
                ctx.fillStyle = fillColor;
                ctx.moveTo(contour[0].x, contour[0].y)
                contour.slice(1).forEach(c => {
                    ctx.lineTo(c.x, c.y)
                })
                //ctx.stroke()
                ctx.closePath();
                ctx.fill();
            } else if (type === 'instance') {
                let {contours, boundingBox, centerOfMass} = data
                let instanceSettings = settings.instance

                // polygon
                let {fillColor} = instanceSettings.contour.classificationLabels["2"]
                contours.forEach(entry => {
                    let {contour} = entry
                    ctx.beginPath();
                    ctx.fillStyle = fillColor;
                    ctx.moveTo(contour[0].x, contour[0].y)
                    contour.slice(1).forEach(c => {
                        ctx.lineTo(c.x, c.y)
                    })
                    ctx.closePath();
                    ctx.fill();
                })

                // bounding box
                let {color} = instanceSettings.boundingBox
                ctx.strokeStyle = color
                ctx.strokeRect(boundingBox.x, boundingBox.y, boundingBox.width, boundingBox.height)

                // center of mass
                let {centerOfMassColor} = instanceSettings.centerOfMass
                ctx.beginPath();
                ctx.lineWidth = Math.max(0.3, 0.3 / camera.zoom)
                ctx.strokeStyle = centerOfMassColor
                ctx.moveTo(centerOfMass.x - 1, centerOfMass.y - 1)
                ctx.lineTo(centerOfMass.x + 1, centerOfMass.y + 1)
                ctx.moveTo(centerOfMass.x + 1, centerOfMass.y - 1)
                ctx.lineTo(centerOfMass.x - 1, centerOfMass.y + 1)
                ctx.stroke()

            }

            ctx.restore();
        })

    })
}

export const drawEverything = (ctx, canvas, tileData, layers, settings, camera) => {

    if (!canvas || !ctx)
        return

    // Clear the entire canvas

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.save()
    ctx.scale(camera.zoom, camera.zoom)
    ctx.translate(-camera.x, -camera.y)
    let foundIncomplete = false
    foundIncomplete |= drawTiles(ctx, canvas, tileData, settings, camera)
    foundIncomplete |= drawDebug(ctx, canvas, tileData, settings, camera)
    foundIncomplete |= drawLayers(ctx, canvas, layers, settings, camera)
    ctx.restore()


    return foundIncomplete

}